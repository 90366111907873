import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import { DashboardNavbar } from 'src/components/organisms/layouts/DashboardNavbar'
import { DashboardSidebar } from 'src/components/organisms/layouts/DashboardSidebar'

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280
  }
}))

export const Layout: React.FC = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true)

  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '100%',
            padding: '16px',
            position : 'relative'
          }}>
          {children}
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      <DashboardSidebar onClose={() => setSidebarOpen(false)} open={isSidebarOpen} />
    </>
  )
}
