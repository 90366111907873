import {styled} from '@mui/material/styles';
import React from 'react';

export const Logo = styled(({variant}: PropsType) => {

  const color = variant === 'light' ? '#FFF' : '#FFF';

  return (
    <svg version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         x="0px" y="0px"
         viewBox="0 0 1024 488">
      <g transform="translate(0 -540.36)">
        <g>
          <path fill={color} d="M419.5,816.76c-5.5,0-10,4.5-10,10v181.6h-331v-181.6c0-5.5-4.5-10-10-10s-10,4.5-10,10v191.6c0,5.5,4.5,10,10,10h351
                  c5.5,0,10-4.5,10-10v-191.6C429.5,821.26,425,816.76,419.5,816.76z"
          />
          <path fill={color} d="M110.9,618.56l51.3,23.2v3.3v29.5v20.4c0,0.8,0.1,1.6,0.3,2.3c-20.5,36-12.1,81.1,21.3,108.3
                  c17.2,14.3,38.9,21.5,60.5,21.5c21.6,0,43.3-7.2,60.5-21.5c16.9-14.1,28-33.4,31.1-54.4c2.8-18.6-0.8-37.3-10.2-53.6
                  c0.2-0.8,0.3-1.7,0.3-2.6v-20.4v-29.5v-3.3l21.7-9.8v23v9.8c0,5.5,4.5,10,10,10s10-4.5,10-10v-9.8v-30.3v-1.7l9.6-4.3
                  c3.6-1.6,5.9-5.2,5.9-9.1c-0.1-4-2.4-7.6-5.9-9.2l-129.1-59.1c-2.6-1.2-5.7-1.2-8.3,0l-129.1,59.1c-3.6,1.6-5.8,5.2-5.8,9.1
                  C105,613.36,107.3,616.96,110.9,618.56z M291.8,790.16L291.8,790.16c-27.2,22.7-68.2,22.7-95.4,0l-0.1-0.1
                  c-25.5-20.7-32.1-55.2-16.7-82.6l61.7,17.5c0.9,0.3,1.8,0.4,2.7,0.4s1.8-0.1,2.7-0.4l61.5-17.5
                  C324,734.86,317.6,768.76,291.8,790.16z M182.1,687.46v-12.9v-23.8l57.8,26.1c1.3,0.6,2.7,0.9,4.1,0.9c1.4,0,2.8-0.3,4.1-0.9
                  l57.8-26.1v23.8v12.9l-61.9,17.6L182.1,687.46z M244,561.36l104.9,48L244,656.76l-104.9-47.4L244,561.36z"
          />
        </g>
      </g>
    </svg>
  );
})``;

interface PropsType {
  variant: 'primary' | 'light'
}

