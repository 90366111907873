import { Box, Button, ListItem } from '@mui/material'
import { Link } from 'gatsby-material-ui-components'
import React from 'react'

export const NavItem = ({ href, icon, title, ...others }: PropsType) => {
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2
      }}
      {...others}>
      <Link to={href}>
        <Button
          startIcon={icon}
          disableRipple
          sx={{
            borderRadius: 1,
            color: 'neutral.300',
            justifyContent: 'flex-start',
            px: 3,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            '& .MuiButton-startIcon': {
              color: 'neutral.400'
            },
            '&:hover': {
              backgroundColor: 'rgba(255,255,255, 0.08)'
            }
          }}>
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
        </Button>
      </Link>
    </ListItem>
  )
}

interface PropsType {
  href?: string
  icon?: any
  title?: string,
  onClick?: () => void
}
