import AssignmentIcon from '@mui/icons-material/Assignment'
import LogoutIcon from '@mui/icons-material/Logout'
import { Box, Divider, Drawer, useMediaQuery } from '@mui/material'
import { Link } from '@reach/router'
import React, { useEffect } from 'react'
import { Logo } from 'src/components/atoms/logo'
import { NavItem } from 'src/components/molecules/navigation'
import { ChartBarIcon } from 'src/icons/chart-bar'
import { CogIcon } from 'src/icons/cog'
import { UserIcon } from 'src/icons/user'
import { supabaseClient as supabase } from 'src/utils/supabase'

const items = [
  {
    href: '/',
    icon: <ChartBarIcon fontSize="small" />,
    title: 'Tableau de bord'
  },
  {
    href: '/tasks',
    icon: <AssignmentIcon fontSize="small" />,
    title: 'Mes tâches'
  },
  {
    href: '/my-profile/',
    icon: <UserIcon fontSize="small" />,
    title: 'Profile'
  },
  {
    href: '/settings/',
    icon: <CogIcon fontSize="small" />,
    title: 'Paramètres'
  }
]

export const DashboardSidebar = ({ open, onClose }: PropsType) => {
  const lgUp = useMediaQuery((theme: any) => theme?.breakpoints?.up('lg'), {
    defaultMatches: true,
    noSsr: false
  })

  useEffect(() => {
    if (open) {
      onClose?.()
    }
  }, [])

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}>
        <div>
          <Box sx={{ p: 3 }}>
            <Link to="/">
              <Logo
                sx={{
                  height: 42,
                  width: 42
                }}
                variant="primary"
              />
            </Link>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 3
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {items.map((item) => (
            <NavItem key={item.title} icon={item.icon} href={item.href} title={item.title} />
          ))}
          <NavItem
            key={'Déconnexion'}
            icon={<LogoutIcon fontSize="small" />}
            title="Déconnexion"
            onClick={() => supabase.auth.signOut()}
          />
        </Box>
        <Divider sx={{ borderColor: '#2D3748' }} />
      </Box>
    </>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent">
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary">
      {content}
    </Drawer>
  )
}

interface PropsType {
  onClose: () => void
  open: boolean
}
